.payment-wrapper{
    width: 100vw;
    height: 1050px;
    background-image:url('../../img/login.png') ;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
}
.payment-form{
    position: absolute;
    top: 240px;
    left: 33.3%;
    width: 550px;
    min-height: 430px;
    border: 1px solid #12120e ;
    padding: 1rem 2rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    margin: 1.5rem 0;
}
.payment-flex-row{
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    flex-wrap: wrap;
}
.payment-btn{
    width: 150px;
    height: 40px;
    background: #0b73c4;
    border-radius: 6px;
    color: white;
    font-size: 18px;
    line-height: 1.4em;
    font-weight: 600;
    transition: all .555s ease;
}
.payment-btn:hover{
    background-color: #333;
}
.payment-btn-non-active{
    width: 150px;
    height: 40px;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #333;
    color: #333;
    font-size: 18px;
    line-height: 1.4em;
    font-weight: 600;
    box-shadow: 3px 5px rgba(51, 51, 51, 0.1);
    transition: all .555s ease;
}
.payment-btn-non-active:hover{
    border: 0;
    background-color: #0b73c4;
    color: white;
}
@media screen and (max-width: 479px) {
    .payment-form{
        width: 350px;
        padding: .5rem 1rem;
        margin: 0 auto;
        position: static;
    }
    .payment-btn,.payment-btn-non-active{
        width: 130px;
    }
    .cube-imege{
        display: none;
    }
    .payment-wrapper{
        padding: 0 1rem;
    }
}
@media screen and (max-width: 769px) {
    .cube-imege{
        display: none;
    }
}