
.bg-blog{
    width: 100vw;
    background-image: url('../../img/bg-blog.png');
    background-repeat: unset;
    
}
.wrapper-content{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 25px;
    
}
.blog-content{
    background: #f2f2f2;
    border: 2px solid #989797;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: .325rem;
    box-sizing: border-box;
    padding: .75rem;
    margin: 0 auto;
    min-height: 250px;
    gap: 1.75rem;
}
.blog-content .count{
    display: flex;
    color: #989797;
    font-size: 14px;
    line-height: 150%;

}
.blog-content .count img{
    width: 22px;
    height: 22px;
    object-fit: cover;
}
.w-845{
    width: 845px;

}
.blog-link{
    color: #989797;
    font-size: 14px;
    line-height: 150px;
    text-decoration: none;
}
.blog-link img{
    width: 22px;
    height: 22px;
    object-fit: cover;
}
.h-50{
    height: 50px;
    margin-bottom: 40px;
}
.flex-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flex-gap-20{
    display: flex;
    gap: 20px;
}
.blog-title{
    font-size: 24px;
    line-height: 150%;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    max-width: 650px;

}
.img-title{
    margin: 1.75rem 0;
    width: 845px;
    height: 425px;
    background: #0b73c4;
}
.pre-title{
    display: flex;
    gap: 1.25rem;
    flex-direction: column;
    max-width: 690px;

}
.content-title{
    font-size: 20px;
    line-height: 150%;
    font-weight: 700;
    max-width: 690px;
}
.content-img{
    max-width: 690px;
    height: 160px;
    border-radius: .5rem;
    background: #0b73c4;
    object-fit: cover;
}
.blog-form{
    margin: 1.75rem 0;
    min-height: 276px;
    border: 2px solid #989797;
    border-radius: 6px;
    background-color: #f2f2f2;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
}
.flex-col-gap-20{
    display: flex;
    flex-direction: column;
    gap: .75rem;
}
.blog-form input[type="text"], .blog-form textarea{
    width: 340px;
    height: 60px;
    background-color: #fff;
    border: 1px solid #989797;
    padding: 15px;
    font-size: 18px;
    transition: all .555s;
    border-radius: .275rem;
    resize: none;
}
.blog-form textarea{
    height: 150px;
}
.blog-form input[type="button"]{
    margin-top: 10px;
    color: #fff;
    width: 340px;
    background-color: #0b73c4;
    font-size: 18px;
    transition: all .555s;
    border-radius: .275rem;
    height: 65px;
}
.blog-comment{
    background-color: #f2f2f2;
    border-radius: .325rem;
    border: 2px solid #989797;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin: 1.5rem 0;
    padding: .5rem 1.25rem;
}
.blog-comment .title{
    font-size: 18px;
    line-height: 150%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: .5rem 90px;
    gap: .5rem;
}
.blog-comment .title img{
    background-color: #0b73c4;
    border-radius: 110%;
    width: 65px;
    height: 65px;
}
.blog-comment .text-content{
    margin: 0 auto;
    max-width: 650px;
    font-size: 17px;
    line-height: 150%;

}
@media screen and (max-width: 479px) {
    .w-845{
        width: 100vw;
        border-radius: 0;
    }
    .blog-form input[type="text"], .blog-form textarea,.blog-form input[type="button"]{
        width: 320px;
    }
    .blog-comment .title{
        margin: 0;
    }
}