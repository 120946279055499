.service-container{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.service-items{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 5rem 0 0;
}
.service-item{
    padding: 3rem;
    border: 1px solid #333333;
    width: 595px;
    min-height: 180px;
    border-radius: .5rem;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    transition: all .555s;
    transition-delay: unset;
}
.service-item .title{
    font-size: 22px;
    line-height: 1.4em;
}
.service-item .text-item{
    font-size: 20px;
    line-height: 1.4em;
    font-weight: 500;
    transition: all .555s;
}
.service-item:hover{
    border-color: #0b73c4;
    transition: all .555s;
}
.service-button{
    background-color: #0b73c4;
    color: white;
    font-size: 21px;
    line-height: 1.4em;
    padding: .5rem .75rem;
    width: 1200px;
    border-radius: .25rem;
    height: 60px;
    margin-top: 5px;
    transition: all .555s linear;
    transition-delay: unset;
}
.service-button:hover{
    background-color: #333333;
}
.service-wrapper{
    width: 100vw;
    min-height: 100vh;
    background-image: url('../../img/bg-blog.png');
    background-repeat: repeat;
    animation: moveBackground 5s infinite ease alternate-reverse;
    transition: all .555ms ease ;
    will-change: transition,background-position,animation ;
    transform-origin:center;
}


@keyframes moveBackground {
    0% {
        background-position: 0 0;
        transition: all .555ms ease unset ;
    }
    100% {
        background-position: 15px 5px;
        transition: all .555ms ease unset ;
    }
}
@media screen and (max-width: 479px) {
    .service-item{
        width: 320px;
    }
    .service-button{
        width: 320px;
    }

}