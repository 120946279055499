.lead-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
    line-height: 150%;
    margin: 0 auto;
}
.lead-input{
    width: 20rem;
    height: 3.5rem;
    outline: none;
    transition: all .555s;
    border: 1px solid rgba(0, 0, 0, 0);
    margin-bottom: 15px;
    padding-left: 15px;
    font-size: 18px;
    box-shadow: inset 0 -3px #0b73c4;

}
.lead-input:hover,.lead-input:focus{
    transition: all .555s;
    padding-left: 5px;
}
.lead-button{
    width: 20rem;
    height: 3.5rem;
    padding: .325rem .5rem;
    color: white;
    background-color: #0b73c4;
    border-radius: .375rem;
    transition: all .555s;
}
.lead-button:hover{
    background-color: #333333;
    transition: all .555s;
}
.lead-container{
    display: flex;
    gap: 45px;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem;
    max-width: 970px;
    margin: 0 0 5rem 4rem;
    border: 1px solid #333333;
    border-radius: .375rem;
    background-color: #fff;
}
.lead-container:hover{
    border-color: #0b73c4;
}
.lead-title{
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4em;
}
.lead-pre-title{
    margin: 3.75rem 0 .125rem .25rem;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3em;
}
.lead-close{
    font-size: 32px;
    font-weight: 700;
    color: red;
    background-color: rgba(0, 0, 0, 0);
}
.flex-row-lead{
    display: flex;
    justify-content: space-between;
    align-items: self-start;
}