.MainServices-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../img/bg-blog.png');
    background-repeat: revert;
}
.MainServices-Promo{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Promo-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 80%;
    z-index: 40;
}
.Promo-title{
    display: flex;
    flex-direction: column;
    font-size: 32px;
    line-height: 1.4em;
    font-weight: 700;
    max-width: 380px;
    margin: 0 1rem;
}
.Promo-pretitle{
    font-size: 21px;
    font-weight: 500;
    line-height: 1.4em;
    max-width: 600px;
}
.Promo-btn-primary{
    height: 60px;
    width: 240px;
    background-color: #0b73c4;
    color: white;
    border-radius: 6px;
    box-shadow: 5px 5px rgba(85, 85, 85, 0.4);
    font-size: 22px;
    line-height: 1.4em;
    transition: all .555s linear;
}
.Promo-btn-primary:hover{
    background-color: #333;
}
.Promo-btn{
    height: 60px;
    width: 240px;
    border: 1px solid #333333;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 5px 5px rgba(85, 85, 85, 0.4);
    color: #0b73c4;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.4em;
    transition: all .555s linear;
}
.Promo-btn:hover{
    border: 0;
    background-color: #0b73c4;
    color: white;
}
.flex-row-gap-75{
    margin-top: 2rem;
    display: flex;
    gap: 75px;
}
.Promo-bg-elements{
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}
.bg-element{
    width: 260px;
    height: 80px;
    background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(155, 155, 155, 0.5));
    border-radius: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #333;
    font-size: 18px;
    line-height: 1.4em;
}
#telegram{
    width: 200px;
    height: 90px;
    margin:0 0 280px 140px;
    filter: blur(1px);
    -webkit-filter: blur(1px);
}
#facebook{
    width: 200px;
    height: 90px;
    margin: 45px 15px 15px 1100px;
    filter: blur(1px);
    -webkit-filter: blur(1px);
}
#reddit{
    width: 200px;
    height: 90px;
    margin: 230px -140px 0;
    filter: blur(1px);
    -webkit-filter: blur(1px);
}
.content-bg-f2{
    background-color: #f2f2f2;
    width: 100vw;
    min-height: 60vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 45px;
    padding: 1rem 4rem;
}
.content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100vw;
    min-height: 60vh;
    gap: 45px;
    padding: 0 4rem;
    margin: 2rem 0;

}
.flex-column-gap-20{
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #333;
    margin: 0 auto;
}
.content-title-services{
    max-width: 560px;
    font-size: 32px;
    line-height: 1.4em;
    font-weight: 700;
}
.content-pretitle{
    font-size: 21px;
    line-height: 1.4em;
    font-weight: 500;
    max-width: 500px;

}
.box-content{
    padding: 4rem;
    background: #f2f2f2;
    border-radius: 8px;
    min-width: 270px;
    max-width: 680px;
    min-height: 540px;
    margin-bottom: 2.5rem ;
}
.content-lead-btn{
    background-color: #0b73c4;
    color: white;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4em;
    padding: 1rem 2.5rem;
    border-radius: 6px;
    text-align: center;

}
.content-text{
    font-size: 17px;
    line-height: 1.4em;
    font-weight: 400;
    max-width: 560px;
}
.Promo-bg-elements img{
    width: 200px;
    object-fit: contain;
}
.content-image, .content-image img{
    max-width: 580px;
    max-height: 530px;
    border-radius: 12px;
    object-fit: contain;
}
.blur-1{
    filter: blur(1px);
}
.product-lead-form{
    position: fixed;
    left: auto;
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid rgba(51, 51, 51, 0.4);

}
@media screen and (max-width: 991px){
    .Promo-bg-elements{
        display: none;
    }
    .nav-menu-13{
        display: none;
    }
    .MainServices-wrapper{
        width: 100vw;
    }
    .lead-container{
        gap: 15px;
    }
    .service-items{
        padding: 0;
    }
    .product-lead-form{
        top: 0;
    }
}
@media screen and (max-width: 479px) {
    .Promo-bg-elements{
        display: none;
    }
    .Promo-title{
        max-width: 270px;
    }
    .Promo-pretitle{
        max-width: 320px;
        box-sizing: border-box;
    }
    .Promo-btn,.Promo-btn-primary{
        width: 140px;
        font-size: 17px;
    }
    .content-bg-f2{
        width: 100vw;
    }
    .content-pretitle{
        margin: 0 auto;
    }
    .box-content{
        width: 320px;
        padding: 2rem;
    }
    .content{
        padding: 2rem;
    }
    .content-image, .content-image img{
        width: 350px;
        height: 320px;
        filter: blur(1px);
    }
}