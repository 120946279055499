.rewiews-wrapper{
    width: 100vw;
    padding: 2rem 5rem;
    min-height: 100vh;
    background-image: url("../../img/bg-blog.png");
    background-repeat: repeat;
    animation: moveBackground 5s infinite ease alternate-reverse;
    transition: all .555ms ease ;
}
 .comment-litle{
     display: block;
     position: relative;
}
 .comment-litle{
    display: none;
}
element {
    width: 100%;
    display: inline-block;
}
.flex-col-wrap{
}
.comment-litle :where(.css-dev-only-do-not-override-pr0fja).ant-carousel .slick-list .slick-slide > div > div,
.comment-litle  :where(.css-dev-only-do-not-override-pr0fja).ant-carousel .slick-list .slick-slide,
.comment-litle :where(.css-dev-only-do-not-override-pr0fja).ant-carousel .slick-list div.slick-slide.slick-active,
.comment-litle :where(.css-dev-only-do-not-override-pr0fja).ant-carousel .slick-list .slick-slide{
    height: 250px;
}
@media screen and (max-width: 991px){
     .comment-big{
        display: none;
    }
    .comment-litle{
        display: block;
    }
}