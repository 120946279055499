
.form-ach{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;

}
.form-login .title{
    font-size: 22px;
    font-weight: 700;
    display: flex;
    gap: 5px;
}
input[type="file"]{
    display: none;
}
.screen-check{
    box-sizing: border-box;
    border: 1px solid #333;
    display: inline-block;
    width: 320px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: .75rem 0;
    color: #333;
    cursor: pointer;

}
.screen-check:hover{
    background-color: #0b73c4;
    color: white;
    border: 0;
}
.flex-row{
    display: flex;
    flex-direction: row;
    gap: 1.25rem;
}

@media screen and (max-width: 479px) {
    .screen-check{
        width: 150px;
    }
}