.wrapper-content-blog{
    max-width: 1200px;
    margin: 0 auto 2.5rem auto;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 25px;
}
.nav-blog{
    width: 310px;
    border: 1px solid #989797;
    background: #f2f2f2;
    box-sizing: border-box;
    padding: 1.75rem;
    border-radius: 8px;
}
.must-read{
    width: 527px;
    min-height: 250px;
    border: 1px solid #989797;
    background: #f2f2f2;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    border-radius: 8px;
}
.box-blue{
    width: 30px;
    height: 30px;
    border-radius: .325rem;
    padding: 1rem;
    background: #0b73c4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.flex-row-beatwen .content-text{
    margin-right: 2.5rem;
}
.link-blog{
    width: 310px;
    height: 360px;
    border: 1px solid #989797;
    border-bottom: 4px solid #0b73c4;
    background: #f2f2f2;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 4px;
}
.link-blog-img{
    background: #0b73c4;
    border-radius: 4px;
    object-fit: cover;
    width: 270px;
    height: 230px;
}
.link-blog-content{
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.flex-col{
    display: flex;
    flex-direction: column;    
    gap: 15px;
}
.flex-row-center{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0; 
}
.blog-flex-row-gap-20{
    display: flex;
    align-items: center;
    gap: 1.25rem;
    width: 250px;
}
.blog-flex-col-gap-20{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    box-sizing: border-box;
    width: 250px;

}
.flex-col-gap-6{
    margin: .5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;

}
.flex-row-wrap{
    margin: 1.75rem 0 ;
    flex-wrap: wrap;
    display: flex;
    gap: .625rem;
    align-items: center;

}
.title{
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
}
.font-14{
    font-size: 14px;
    line-height: 150%;
    max-width: 400px;
}
button.active{
    width: 167px;
    height: 45px;
    border-radius: 8px;
    color: white;
    background-color: #0b73c4;
    padding: 10px;
}
button.non-active{
    width: 167px;
    height: 45px;
    border-radius: 8px;
    color: black;
    background-color: #f2f2f2;
    padding: 10px;
}

.Item-blog{
    text-decoration: none;
    color: #000;
    width: 527px;
    min-height: 434px;
    background: #f2f2f2;
    border: 1px solid #989797;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
}
.Item-blog-content{
    padding: 2rem;
    box-sizing: border-box;
    height:150px;
}
.Item-blog-img{
    object-fit: cover;
    background: #0b73c4;
    width: 527px;
    height: 281px;
}
.items-blog{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.input-search{
    border-radius: .5rem;
    width:258px ;
    padding: 1.25rem;
    box-sizing: border-box;  
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../img/Search.webp");
    background-position-y:50%;
    background-position-x: 97%;
    background-repeat: no-repeat;
    background-size: 20px;
}
.input-search:focus{
    background-position: -30px;
    
}
img.icon{
    height: 33px;
    width: 33px;
    object-fit: cover;
}
.bg-blog{
    width: 100vw;
    background-image: url('../../img/bg-blog.png');
    background-repeat: unset;
    animation: moveBackground 6s infinite linear alternate;
    
}
@media screen and (max-width: 479px) {
    .Item-blog{
        width: 100vw;
        margin: 0;
        border-radius: 0;
    }
    .flex-row-center{
        max-width: 100vw;
    }
    .must-read{
        width: 100vw;
    }
    .wrapper-content-blog{
        margin-top: 2.5rem;
        justify-content: center;
        align-items: center;
    }
}