.Dropdown{
    padding: 1rem 0;
    transition: all .555s ease;
}
.Dropdown-list{
    position: absolute;
    width: 220px;
    min-height: 50px;
    background-color: #fff;
    padding: 1rem 0 0 0;
    z-index: 60;
    left: -2vw;
    gap: 25px;
    flex-direction: column;
    display: none;
    transition: all .555s ease;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4em;
}
.Dropdown:hover .Dropdown-list{
    display: flex;

}
.Dropdown .Dropdown-list div{
    margin: 1rem 0 ;
    width: 185px;
}