.cWrapper.active{
    cursor: -webkit-grabbing;
    cursor: grabbing;
}   
.Item{
    width: 100%;
    max-width: 580px;
    height: 580px;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 32px;
    line-height: 150%;
    font-weight: 700;
    padding: 0 1rem;
    margin: 0 1.5rem;
    user-select: none;
}
.Item .Item-text{
    opacity: 0;
    color: white;
    mix-blend-mode: difference;
    width: 580px;
    z-index: 30;
    height: 75px;
    text-decoration: none;
}
.Item:hover .Item-text{
    opacity: 1;

}
#about{
    margin-top: 50rem;
}
.cWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel {
    width: 100vw;
    height: 600px;
    direction: rtl;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}
.Item:first-child { margin-right: 0px !important;}
.Item:last-child { margin-left: 0px !important;}
.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.cWrapper {
    padding: 0 1.5rem;
}