@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.bg-full{
    width: 100vw;
    font-family: 'Rubik','Montserrat', sans-serif;
}
.Analytics-header{
    background: #f2f2f2;
    width: 100vw;
    padding: 1.5rem 5rem;
    border-bottom: 2px solid #0b73c4;
    min-height: 150px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.125rem;

}
.Analytics-logo-user{
    width: 75px;
    height: 75px;
    border-radius: 100vw;
    background: #0b73c4;
}
.Analytics-title{
    font-size: 22px;
    line-height: 150%;
}
.flex{
    display: flex;
    align-items: center;
    gap: .5rem;
}
.flex-col{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.text-18, .text-18 a{
    font-size: 18px;
    line-height: 150%;
    color: black;
    text-decoration: none;
}
.button-pick{
    padding: .25rem;
    background-color: #fff;
    border:1px solid #0b73c4;
    border-radius: .375rem;
    color: #989797;
}
.button-pick-active{
    background-color: #0b73c4;
    border: 0px;
    color: #fff;
    padding: .25rem;
    border-radius: .375rem;
}
.Analytics-content{
    width: 100vw;
    margin: 0 auto;
    background-color: #f2f2f2;
    min-height: 150px;
    padding: 1.5rem 2.5rem;
    display: flex;
    flex-direction: column;
}
.Analytics-content .title-big{
    display: flex;
    flex-direction: column;
    font-size: 22px;
    line-height: 150%;
    margin-left: 7.5rem;

}
.Analytics-content .title-bold{
    font-size: 32px;
    line-height: 150%;
    font-weight: 700;
}
.Analytics-table{
    width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin: 1.5rem auto;
    border-bottom: 1px solid #0b73c4;
}
.Analytics-table .title{
    font-size: 19px;
    line-height: 150%;
    display: flex;
    flex-direction: row;
    gap: 17rem;
    border-bottom: 1px solid #0b73c4;
}
.Analytics-table .element{
    font-size: 17px;
    line-height: 150%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #0b73c4;
    gap: 15rem;
}
.Analytics-table .element .progress{
    height: 5px;
    width: 210px;
    background-color: #989797;
    border-radius: .325rem;
    margin: 10px 0 ;
}
.-ml-30{
    margin-left: -70px;
    width: 100px;
}