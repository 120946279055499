.login-wrapper{
    width: 100vw;
    height: 100vh;
    background-image:url('../../img/login.png') ;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 0 1rem;
}
.form-login{
    width: 480px;
    min-height: 300px;
    border: 1px solid #12120e ;
    padding: 5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    margin: 1.5rem auto;

}
.form-login .title{
    font-size: 22px;
    font-weight: 700;
    display: flex;
    gap: 5px;
}
@media screen and (max-width: 479px) {
    .form-login{
        width: 350px;
        padding: .5rem 1rem;
        margin: 0 auto;
        position: static;
    }
    .cube-imege{
        display: none;
    }
}
