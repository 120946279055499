.CustomDevelop-wrapper{
    background-image: url('../../img/bg-custom-develop.png');
    background-repeat: revert;
}

@media screen and (max-width: 479px) {
    .Promo-bg-elements{
        display: none;
    }
    .Promo-title{
        max-width: 270px;
    }
    .Promo-pretitle{
        max-width: 320px;
        box-sizing: border-box;
    }
    .Promo-btn,.Promo-btn-primary{
        width: 140px;
        font-size: 17px;
    }
    .content-bg-f2{
        width: 100vw;
    }
    .content-pretitle{
        margin: 0 auto;
    }
    .box-content{
        width: 320px;
        padding: 2rem;
    }
    .content{
        padding: 2rem;
    }
}