.form-paypal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;

}
.form-login .title{
    font-size: 22px;
    font-weight: 700;
    display: flex;
    gap: 5px;
}
input[type="file"]{
    display: none;
}
.screen-check{
    box-sizing: border-box;
    border: 1px solid #333;
    display: inline-block;
    width: 320px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: .75rem 0;
    color: #333;
    cursor: pointer;
}